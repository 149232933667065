import {axiosPost} from "../_helpers";
import {API_PATH} from "../constants";
import {ACTION_TYPE} from "../constants";
import {reloadGakkyuForBasicMenu} from "../_helpers/appState";

export const switchDrawer = () => {return {
    type: ACTION_TYPE.SWITCH_DRAWER};};
export const switchSnackBarIsClose = () => {return {
    type: ACTION_TYPE.SNACK_BAR_IS_CLOSE}};

export const loadFinishGakkyus = res => {
    return {
        type: ACTION_TYPE.LOAD_FINISH_GAKKYUS,
        gakkyu: res.data.gakkyus,
        basicMenu: reloadGakkyuForBasicMenu(res.data.gakkyus),
    };};

export const loadErrorGakkyus = err => {return {
    type: ACTION_TYPE.LOAD_ERROR_GAKKYUS,
    error: err};};
export const loadGakkyus = () => {
    return dispatch => {
        return axiosPost(API_PATH.loadGakkyus, {})
            .then(res => dispatch(loadFinishGakkyus(res)))
            .catch(err => dispatch(loadErrorGakkyus(err)))}
};