import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();


export const linkToManageTeachers = e => {
    history.push('');
};
export const linkToSchoolRegisterManagementTop = e => {
    history.push('/manage/parentsAndChildren');
};