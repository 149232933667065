import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';



const userStyles = makeStyles(theme => ({
  pageNavBarRoot: {
    zIndex: theme.zIndex.drawer + 1,
    flexGrow: 1,
  },
}));

function PageNavBar(props) {
  const classes = userStyles();
  return (
    <div className={classes.pageNavBarRoot}>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <Typography variant="h6" color="inherit">
            {props.title}  {props.pageTitle}
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default PageNavBar;