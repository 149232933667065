import { store } from './store';

export const createMenuItem = (key, title, iconKey, link, index, children) => {
    return {key, title, iconKey, link, index, children};
};
function gakunenBy(gakurei) {
    const gakunen = store.getState().appState.gakunen;
    return gakunen.find(g => g.gakurei === parseInt(gakurei));
}

export function gakkyuById(gakkyuId) {
    const gakkyus = store.getState().appState.gakkyu;
    return gakkyus.find(g => g.id == gakkyuId);
}

export function reloadGakkyuForBasicMenu(gakkyu) {
    const basicMenu = store.getState().appState.basicMenu;
    const gakkyuMenu = basicMenu.find(m => m.key === 'gakkyu');

    let indexCount = 1;
    gakkyu.forEach(g => {
        // let gakunenMenu = gakkyuMenu.children.find(c => c.key === 'gakurei-'+ g.gakurei);
        // if (!gakunenMenu) {
        //     const gakunen = gakunenBy(g.gakurei);
        //     gakunenMenu = createMenuItem(
        //         'gakurei-' + g.gakurei,
        //         gakunen ? gakunen.gakunenName : '学年なし',
        //         'apps',
        //         '',
        //         indexCount,
        //         []);
        //     gakkyuMenu.children.push(gakunenMenu);
        //     indexCount++;
        // }
        let gakkyuMenuItem = gakkyuMenu.children.find(c => c.key === g.gakurei + '-' + g.id);
        if (!gakkyuMenuItem) {
            gakkyuMenuItem = createMenuItem(
                g.gakurei + '-' + g.id,
                g.name,
                'contacts',
                '/manage/gakkyu/' + g.id,
                g.id,
                []);
            gakkyuMenu.children.push(gakkyuMenuItem);
        }
    });
    return basicMenu;
}