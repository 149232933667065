import React from 'react';
import PageNavBar from '../Common/PageNavBar';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { interval } from 'rxjs';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "../Accessory/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const styles = makeStyles(theme => ({

  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  mainDate: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    borderRadius: '.2rem',
    width: 'fit-content',
    padding: '.5rem',
    height: '3.5rem',
    textAlign: 'center',
    fontSize: '1.8rem',
    lineHeight: '3rem',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  mainClock: {
    color: theme.palette.secondary.contrastText,
    height: '5rem',
    textAlign: 'center',
    fontSize: '5rem',
    lineHeight: '5rem',
  },
  newsTitle: {

  },
  newsBody: {

  },
}));

function Top(props) {
  const [date, setDate] = React.useState(moment())

  React.useEffect(() => {
    const subscription = interval(1000).subscribe(() => {
      setDate(moment())
    });
    return () => {
      subscription.unsubscribe()
    }
  }, []);

  const classes = styles();
  return (
      <React.Fragment>
        <PageNavBar title={props.title} pageTitle={'ダッシュボード'}/>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>

                <Typography className={classes.mainDate}>
                  {date.format('YYYY年MM月DD日(ddd)')}
                </Typography>
                <Typography className={classes.mainClock}>
                  {date.format('HH:mm:ss')}
                </Typography>

              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography
                    className={classes.newsTitle}
                >
                  {'お知らせ - News -'}
                </Typography>
                <Divider/>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Icon iconkey={'announcement'}/>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                              預かり保育管理画面のいくつかの機能を更新しました
                            </Typography>
                            {" — 2019.12.23"}
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <div>{"・管理画面からの降園処理における時間の選択肢を7:00-21:00に広げました"}</div>
                            <div>{"・管理画面からの預かり保育の登録における時間の選択肢を7:00-21:00に広げました"}</div>
                            <div>{"・降園処理画面（Leaving）の一部機能を修正しました（以下【降】）"}</div>
                            <div>{"・【降】園児リストの視認性を改善しました"}</div>
                            <div>{"・【降】降園処理ボタンを一回押すだけで降園処理するようにしました（確認ダイアログの廃止）"}</div>
                            <div>{"・【降】降園処理時におやつの有無の確認ができるようにしました"}</div>
                          </React.Fragment>
                        }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Icon iconkey={'announcement'}/>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                              預かり保育管理画面のいくつかの機能を更新しました
                            </Typography>
                            {" — 2019.12.19"}
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <div>{"・「今日の預かり保育」の日付選択ボックスを修正しました"}</div>
                            <div>{"・「月間予定」から月別集計表（平日）を出力できるようになりました"}</div>
                            <div>{"・「個別利用状況」から提供証明書の発行が可能になりました"}</div>
                          </React.Fragment>
                        }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Icon iconkey={'announcement'}/>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                              預かり保育管理画面のいくつかの機能を更新しました
                            </Typography>
                            {" — 2019.11.27"}
                          </React.Fragment>
                        }
                        secondary={
                          <React.Fragment>
                            <div>{"・「今日の預かり保育」の日付選択ボックスが使いやすくなりました"}</div>
                            <div>{"・「今日の預かり保育」から降園処理/修正が可能になりました"}</div>
                            <div>{"・「預かり保育登録」の園児選択から学年をなくしました（学級→園児と選択できます）"}</div>
                            <div>{"・「預かり保育登録」の日付・時間選択の機能を強化しました"}</div>
                            <div>{"・「預かり保育登録」の課外体育の項目を事前登録されたスケジュールに連動するように強化しました"}</div>
                            <div>{"・その他、Loading時の細かな不具合などを修正"}</div>
                          </React.Fragment>
                        }
                    />
                  </ListItem>
                  {/*<Divider variant="inset" component="li" />*/}
                  {/*<ListItem>*/}
                  {/*  <ListItemAvatar>*/}
                  {/*    <Avatar>*/}
                  {/*      <Icon iconkey={'announcement'} />*/}
                  {/*    </Avatar>*/}
                  {/*  </ListItemAvatar>*/}
                  {/*  <ListItemText primary="Work" secondary="Jan 7, 2014" />*/}
                  {/*</ListItem>*/}
                  {/*<Divider variant="inset" component="li" />*/}
                  {/*<ListItem>*/}
                  {/*  <ListItemAvatar>*/}
                  {/*    <Avatar>*/}
                  {/*      <Icon iconkey={'announcement'} />*/}
                  {/*    </Avatar>*/}
                  {/*  </ListItemAvatar>*/}
                  {/*  <ListItemText primary="Vacation" secondary="July 20, 2014" />*/}
                  {/*</ListItem>*/}
                </List>

              </Paper>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
  );
};

export default Top;