import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import { buildSelectTimesFromAndTo} from "../../../_helpers/extendedDaycare";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyle = makeStyles(theme => ({
    formControl: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: '40%',
    },
}));

export default function LeavingConfirmDialog(props) {
    const classes = useStyle();

    const exSchedule = props.currentDateSchedule;
    /**
     * 「降園処理を行います。この時間で確定してよろしいですか？」
     * 表示項目：園児学級、園児氏名
     * 変更可能項目：開始時間、降園時間、おやつの有無
     * ボタン：降園、キャンセル
     */
    const handleAgree = () => {
        props.agreeAction();
    };

    const handleDisagree = () => {
        props.disagreeAction();
    };

    if (props.isOpen) {
        // const {fromSelection, toSelection} = buildSelectTimesFromAndTo(exSchedule.fromTime, exSchedule.toTime, props.leavingStatus.fromTime);
        //仕様注：選択肢は7:00-21:00まで受付可能
        const {fromSelection, toSelection} = buildSelectTimesFromAndTo('07:00:00', '21:00:00', props.leavingStatus.fromTime);

        return (
            <div>
                <Dialog
                    open={props.isOpen}
                    onClose={handleDisagree}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {'[' + props.selectedRecord.gakkyuName + 'ぐみ/' + props.selectedRecord.studentNameKana + ']さんを降園処理します'}
                        </DialogContentText>

                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="fromtime-native-label-placeholder">
                                開始時刻
                            </InputLabel>
                            <NativeSelect
                                defaultValue={props.leavingStatus.fromTime}
                                onChange={e=>props.handleChangeLeavingStatus(e,'fromTime')}
                                inputProps={{
                                    name: 'fromTime',
                                    id: 'filled-fromtime-native-simple',
                                    'aria-label': 'fromTime' }}
                            >
                                {fromSelection.map(t => {
                                    return (<option key={'from-'+ t.format('HHmm') +'-time'} value={t.format('HH:mm')}>{t.format('HH:mm')}</option>)
                                })}
                            </NativeSelect>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="fromtime-native-label-placeholder">
                                降園時刻
                            </InputLabel>
                            <NativeSelect
                                defaultValue={props.leavingStatus.toTime}
                                onChange={e=>props.handleChangeLeavingStatus(e,'toTime')}
                                inputProps={{
                                    name: 'toTime',
                                    id: 'filled-totime-native-simple',
                                    'aria-label': 'toTime' }}
                            >
                                {toSelection.map(t => {
                                    return (<option key={'to-'+ t.format('HHmm') +'-time'} value={t.format('HH:mm')}>{t.format('HH:mm')}</option>)
                                })}
                            </NativeSelect>
                        </FormControl>

                        <FormHelperText>{'利用時間' + props.leavingStatus.useTime + '分'}</FormHelperText>
                    </DialogContent>
                    <DialogContent>
                        <FormControlLabel
                            value=""
                            control={
                                <Checkbox
                                    className={classes.inputCheckbox}
                                    checked={props.leavingStatus.hasAteSnack}
                                    onChange={e => props.handleChangeLeavingStatus(e, 'hasAteSnack')}
                                    value={'hasAteSnack'} />
                            }
                            label="おかし(100円/回)"
                            labelPlacement="start"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDisagree} color="primary">
                            いいえ
                        </Button>
                        <Button onClick={handleAgree} color="primary" autoFocus>
                            はい
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    } else {
        return (
            <React.Fragment>
                <div>
                    <Dialog
                        open={props.isOpen}
                        onClose={handleDisagree}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        now loading...
                    </Dialog>
                </div>
            </React.Fragment>
        );
    }
}