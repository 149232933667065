import React from 'react';
import clsx from 'clsx';
import {Redirect, Route, Switch} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import DrawerMenuListItem from '../components/Common/Drawer/DrawerMenuListItem';
import Divider from '@material-ui/core/Divider';
// icons

// manage
import Top from '../components/ManagePages/Top';
// manage/product
import GakkyuTop from './Page/Gakkyu/Top';
import AttendanceTop from './Page/Attendance/Top';
import ExtendDaycareTop from './Page/ExtendedDaycare/Top';
import NotificationTop from './Page/Notification/Top';
import DiaryTop from './Page/Diary/Top';
import ParentsAndChildrenTop from './Page/Manage/SchoolRegisterManagement/Top';
import { connect } from 'react-redux';
import CssBaseline from "@material-ui/core/CssBaseline";
import NavBar from "../components/Common/NavBar";
import {bindActionCreators} from "redux";
import * as Actions from "../actions";
import TeacherList from "./Page/Manage/SchoolRegisterManagement/TeacherList";
import AddTeacher from '../components/ManagePages/User/AddTeacher';

const drawerWidth = 240;
const classes = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: theme.palette.primary.main,
        borderRight: 0,
    },
    main: {
        width: '100%',
        padding: 0,
    },
    content: {
        flexGrow: 1,
        padding: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    toolbar: theme.mixins.toolbar,
    listRoot: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    listNested: {
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.primary.main,
    },
});


class Manage extends React.Component {
    componentDidMount() {
        this.props.studentsLoad();
        this.props.loadGakkyus();
    }

    state = {};

  render() {
      const {classes} = this.props;
      const preSchoolName = this.props.preSchool.preSchoolName;
      const basicMenu = this.props.appState.basicMenu;

      if (this.props.appState.gakkyu.length > 0
          && this.props.students.students.length > 0) {
          return (
              <React.Fragment>
                  <CssBaseline />
                  <NavBar {...this.props} position="fixed"/>
                  <div className={classes.root}>
                      <Drawer
                          variant="persistent"
                          className={classes.drawer}
                          anchor="left"
                          classes={{
                              paper: classes.drawerPaper,
                          }}
                          open={this.props.appState.openDrawer}
                      >
                          <div className={classes.toolbar} />
                          <List
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                              className={classes.listRoot}>
                              {basicMenu.map((item) => (
                                  <DrawerMenuListItem key={item.key} item={item} classes={classes} history={this.props.history}/>))}
                          </List>
                          <Divider />
                          <List
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                              className={classes.listRoot}>
                              {this.props.appState.extendMenu.map((item) => (
                                  <DrawerMenuListItem key={item.key} item={item} classes={classes} history={this.props.history}/>))}
                          </List>
                      </Drawer>
                      <main className={clsx(classes.content,{
                          [classes.contentShift]: this.props.appState.openDrawer,
                      })}>
                          <div className={classes.toolbar}/>
                          <Switch>
                              <Route exact path={this.props.match.path + ''}><Top {...this.props} title={preSchoolName}/></Route>

                              {/*<Route exact path={this.props.match.path + '/gakkyu'}><GakkyuTop {...this.props} title={preSchoolName}/></Route>*/}
                              {/*<Route exact path={this.props.match.path + '/gakkyu/:gakkyuId'}><GakkyuTop {...this.props} title={preSchoolName}/></Route>*/}

                              {/*<Route exact path={this.props.match.path + '/attendance'}><AttendanceTop {...this.props} title={preSchoolName}/></Route>*/}

                              <Route exact path={this.props.match.path + '/extend-daycare'} render={({ match })=>(<Redirect to={this.props.match.path + '/extend-daycare/for-day'}/>)} />
                              <Route exact path={this.props.match.path + '/extend-daycare/:tabKey'} ><ExtendDaycareTop {...this.props} title={preSchoolName}/></Route>
                              <Route exact path={this.props.match.path + '/extend-daycare/:tabKey/:tabParam'} ><ExtendDaycareTop {...this.props} title={preSchoolName}/></Route>
                              <Route exact path={this.props.match.path + '/extend-daycare/:tabKey/:tabParam/:tabSubParam1'} ><ExtendDaycareTop {...this.props} title={preSchoolName}/></Route>
                              <Route exact path={this.props.match.path + '/extend-daycare/:tabKey/:tabParam/:tabSubParam1/:tabSubParam2'} ><ExtendDaycareTop {...this.props} title={preSchoolName}/></Route>

                              {/*<Route exact path={this.props.match.path + '/notification'}><NotificationTop {...this.props} title={preSchoolName}/></Route>*/}

                              {/*<Route exact path={this.props.match.path + '/Diary'}><DiaryTop {...this.props} title={preSchoolName}/></Route>*/}

                              {/*<Route exact path={this.props.match.path + '/teachers'}><TeacherList {...this.props}/></Route>*/}
                              {/*<Route exact path={this.props.match.path + '/teachers/add'}><AddTeacher {...this.props}/></Route>*/}
                              {/*<Route exact path={this.props.match.path + '/teacher/:teacherId'}><TeacherList {...this.props}/></Route>*/}

                              {/*<Route exact path={this.props.match.path + '/parents-and-children'}><ParentsAndChildrenTop {...this.props}/></Route>*/}

                          </Switch>
                      </main>
                  </div>
              </React.Fragment>
          );
      } else {
          return (
              <React.Fragment>
                  now loading
              </React.Fragment>
          );
      }

  }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(Actions, dispatch)
    };
}

export default connect(
    state => state,
    mapDispatchToProps
)(withStyles(classes)(Manage));