import {ACTION_TYPE} from "../constants";
const initialState = {
    attendanceRecords:[],
};

export default function attendance(state = initialState, action) {
    switch(action.type) {
        case ACTION_TYPE.REGISTER_ATTENDANCE_SUCCESS:
        case ACTION_TYPE.LOAD_ATTENDANCE_FINISH:
            const newRecords = Object.assign({}, state.attendanceRecords);
            if (action.date) {
                newRecords[action.date] = action.records;
            } else if (action.yearMonth) {
                newRecords[action.yearMonth] = action.records;
            }
            return Object.assign({}, state, {
                attendanceRecords: newRecords
            });
        case ACTION_TYPE.ERROR_ATTENDANCE_REQUEST:
            return Object.assign({}, state, {});
        default:
            return state;
    }
}