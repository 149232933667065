import { combineReducers } from 'redux';
import auth from './Auth';
import preSchool from './PreSchool';
import appState from './AppState';
import students from './Students';
import extendedDaycare from './ExtendedDaycare';
import daySchedule from "./DaySchedule";
import attendance from './Attendance';

const reducer = combineReducers({
    auth,
    preSchool,
    appState,
    students,
    extendedDaycare,
    daySchedule,
    attendance,
});

export default reducer;