import React from 'react';
import {withRouter} from 'react-router-dom';
import ListRecordsForDay from "../../../components/ExtendedDaycare/Daily/ListRecordsForDay";
import {store, empty, debug} from "../../../_helpers";
import {leavingExtendedDaycare} from "../../../actions";
import LeavingConfirmDialog from "../../../components/ExtendedDaycare/Daily/LeavingConfirmDialog";
import {calcUseTime, getExtendedDaycareScheduleByDate} from "../../../_helpers/extendedDaycare";
import moment from "moment";

class ForDay extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {};

  componentDidMount() {
    debug('ForDay Component did mount!');

    this.state = {
      selectedExtendedDaycareRecord: null,
      currentDateExtendedDaycareSchedule: getExtendedDaycareScheduleByDate(this.props.currentSelectedDate),
      leavingStatus: {
        fromTime: '00:00',
        toTime: '00:00',
        useTime: 0,
        hasAteSnack: true,
      },
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const exSche = this.props.daySchedule.extendedDaycareSchedule;
    if (exSche.length > 0
        && moment(exSche[0].date, 'YYYY-MM-DD').isSame(moment(this.props.currentSelectedDate, 'YYYY-MM-DD'), 'month')
        && !exSche.find(e => e.date === this.props.currentSelectedDate)) {
      let reselectedDate = this.reselectNearDate(this.props.currentSelectedDate, exSche);
      if (!reselectedDate) {
        reselectedDate = moment(this.props.currentSelectedDate, 'YYYY-MM-DD').add(1, 'months').startOf('month').format('YYYY-MM-DD');
      }
      this.changeDateParam(reselectedDate);
    }
  }

  /**
   * currentSelectedDateが選択肢になかった場合に再設定をするためのメソッド
   */
  reselectNearDate(currentDate, schedule) {
    let defaultSelected = currentDate;
    const sameOrAfterTodaySchedule = schedule.filter(ds => moment(ds.date, 'YYYY-MM-DD')
        .isSameOrAfter(moment(defaultSelected, 'YYYY-MM-DD')));
    if (sameOrAfterTodaySchedule.length > 0) {
      return sameOrAfterTodaySchedule[0].date;
    } else {
      return false;
    }
  }

  changeDateParam(date) {
    this.props.setCurrentSelectedDate(date);
    this.setState({
      currentDateExtendedDaycareSchedule: getExtendedDaycareScheduleByDate(date),
    });
  }

  changeSelectedMonth(yearMonth) {
    this.changeDateParam(yearMonth + '-01');
  }

  changeSelectedDate(date) {
    this.changeDateParam(date);
  }

  handleLeaving(e, id) {
    const daycareRecords = this.props.extendedDaycare.extendedDaycareRecords;
    const currentRecords = daycareRecords[this.props.currentSelectedDate] ? daycareRecords[this.props.currentSelectedDate]:[];
    const selectedRecord = currentRecords.find(r => r.id == id);

    if (selectedRecord){
      const recordTime = calcUseTime(selectedRecord.fromTime, selectedRecord.toTime);
      this.setState({
        selectedExtendedDaycareRecord: selectedRecord,
        currentDateExtendedDaycareSchedule: getExtendedDaycareScheduleByDate(this.props.currentSelectedDate),
        leavingStatus: {
          fromTime: recordTime.fromTime,
          toTime: recordTime.selectedAt,
          useTime: recordTime.useTimeMinutes,
          hasAteSnack: selectedRecord.hasAteSnack,
        },
      });
    } else {
      this.setState({
        selectedExtendedDaycareRecord: null,
      });
    }
  }

  handleLeavingAgreeAction() {

    if (this.state.selectedExtendedDaycareRecord) {
      store.dispatch(leavingExtendedDaycare(
          this.state.selectedExtendedDaycareRecord.id,
          this.state.leavingStatus.fromTime,
          this.state.leavingStatus.toTime,
          this.state.leavingStatus.useTime,
          this.state.leavingStatus.hasAteSnack,
          ));
    }

    this.setState({
      selectedExtendedDaycareRecord: null,
    });
  }

  handleLeavingDisagreeAction() {
    this.setState({
      selectedExtendedDaycareRecord: null,
    });
  }

  handleChangeLeavingStatus(e, name) {
    const selectOrDefaultToTime = (leavingStatus) => {
      const fromTime = moment(leavingStatus.fromTime, 'HH:mm');
      const toTime = moment(leavingStatus.toTime, 'HH:mm');
      if (fromTime.isSameOrAfter(toTime)) {

        return fromTime.clone().add(15, 'm').format('HH:mm');
      }
      return leavingStatus.toTime;
    };

    if (name == 'fromTime') {
      const toTime = selectOrDefaultToTime({
        fromTime: e.target.value,
        toTime: this.state.leavingStatus.toTime
      });

      const recordTime = calcUseTime(e.target.value, toTime);
      this.setState({
        leavingStatus: {
          fromTime: recordTime.fromTime,
          toTime: recordTime.selectedAt,
          useTime: recordTime.useTimeMinutes,
          hasAteSnack: this.state.leavingStatus.hasAteSnack,

        }});
    } else if (name == 'toTime') {
      const toTime = selectOrDefaultToTime({
        fromTime: this.state.leavingStatus.fromTime,
        toTime: e.target.value
      });

      const recordTime = calcUseTime(this.state.leavingStatus.fromTime, toTime);
      this.setState({
        leavingStatus: {
          fromTime: recordTime.fromTime,
          toTime: recordTime.selectedAt,
          useTime: recordTime.useTimeMinutes,
          hasAteSnack: this.state.leavingStatus.hasAteSnack,

        }});
    } else if (name == 'hasAteSnack') {
      const recordTime = calcUseTime(this.state.leavingStatus.fromTime, this.state.leavingStatus.toTime);
      this.setState({
        leavingStatus: {
          fromTime: recordTime.fromTime,
          toTime: recordTime.selectedAt,
          useTime: recordTime.useTimeMinutes,
          hasAteSnack: !this.state.leavingStatus.hasAteSnack,
        }});
    }
  };


  render() {
    return (
        <React.Fragment>
          <ListRecordsForDay
              {...this.props}
              handleSelectMonth={month=>this.changeSelectedMonth(month)}
              handleSelectDay={(e) => this.changeSelectedDate(e)}
              handleLeaving={(e, id) => this.handleLeaving(e, id)}
              extendedDaycareSchedule={this.props.daySchedule.extendedDaycareSchedule}
          />
          <LeavingConfirmDialog
              {...this.props}
              title={'降園処理をします'}
              text={'以下の時間でよろしいですか？'}
              isOpen={!empty(this.state.selectedExtendedDaycareRecord)}
              selectedRecord={this.state.selectedExtendedDaycareRecord}
              currentDateSchedule={this.state.currentDateExtendedDaycareSchedule}
              leavingStatus={this.state.leavingStatus}
              handleChangeLeavingStatus={(e, name) => this.handleChangeLeavingStatus(e, name)}
              agreeAction={() => this.handleLeavingAgreeAction()}
              disagreeAction={() => this.handleLeavingDisagreeAction()}
          />
        </React.Fragment>
    );
  }
}

export default withRouter(ForDay);