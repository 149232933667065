import React from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Title from '../../../../components/Text/Title';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Icon from '../../../../components/Accessory/Icon';
import Fab from '@material-ui/core/Fab';
import AddTeacher from "../../../../components/ManagePages/User/AddTeacher";

export default class TeacherList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            addTeacherDialogIsOpen: false,
        }
    }

    handleClickAddButton(e) {
        this.setState({
            addTeacherDialogIsOpen: true,
        })
    }

    render() {

        const classes = theme => ({
            seeMore: {
                marginTop: theme.spacing(3),
                textAlign: 'right',
            },
            rowHeader: {
                // backgroundColor: green[300],
            },
            margin: {
                margin: theme.spacing(1),
            },
            extendedIcon: {
                marginRight: theme.spacing(1),
            },
            button: {
                margin: theme.spacing(2),
            },
            paper: {
                padding: theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',
            },
        });

        const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
        const students = this.props.students.students;

        console.log('render()');
        console.log(this.state.addTeacherDialogIsOpen);

        return (
            <main className={this.props.contentClass}>
                <div className={this.props.toolbarClass}/>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={fixedHeightPaper}>
                                <Title>{this.props.label}</Title>
                                <div>
                                    <Fab variant="extended"
                                         size="medium"
                                         color="primary"
                                         aria-label="add"
                                         className={classes.margin}
                                         onClick={e => this.handleClickAddButton(e)}>
                                        <Icon iconkey={'addCircle'} />職員を追加
                                    </Fab>
                                </div>
                                <AddTeacher addTeacherDialogIsOpen={this.state.addTeacherDialogIsOpen}/>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>役職</TableCell>
                                            <TableCell>氏名</TableCell>
                                            <TableCell>かな</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Array.isArray(students) ? students.map(row => (
                                            <TableRow key={row.id}>
                                                <TableCell>{'roll'}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.nameKana}</TableCell>
                                                <TableCell>{'options'}</TableCell>
                                            </TableRow>
                                        )) : ''}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>

                    </Grid>
                </Container>
            </main>
        );
    };
}