import React from 'react';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import Title from '../../components/Text/Title';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Icon from '../../components/Accessory/Icon';
import Fab from '@material-ui/core/Fab';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";



const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
    textAlign: 'right',
  },
  rowHeader: {
    // backgroundColor: green[300],
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

export default function StudentList(props) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const students = props.students.students;

  function handleChange(e) {
      props.handleChangeGakkyu(e);
  }

  return (
    <main className={props.contentClass}>
        <div className={props.toolbarClass}/>
        <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
        <Grid item xs={12}>
        <Paper className={fixedHeightPaper}>
            <Title>{props.label}</Title>
            <div>
                <Fab variant="extended"
                     size="medium"
                     color="primary"
                     aria-label="add"
                     className={classes.margin}>
                    <Icon iconkey={'addCircle'} />園児を追加
                </Fab>

                <Select
                    native
                    value={props.currentGakkyuId}
                    onChange={e=>handleChange(e)}
                    input={
                        <OutlinedInput name="month"  id="outlined-gakkyuId-native-simple" />
                    }>
                    {props.gakkyus.map(t => {
                        return (<option key={t.id + '-month'} value={t.id}>{t.name}</option>)
                    })}
                </Select>

                <Button variant="contained" color="primary" className={clsx(classes.button, classes.zeroMarginY)} onClick={e=>props.downloadStudentCodes(e)}>
                    園児コードを取得
                </Button>

            </div>
            <Table size="small">
              <TableHead>
                <TableRow>
                    <TableCell>学級</TableCell>
                    <TableCell>番号</TableCell>
                    <TableCell>氏名</TableCell>
                    <TableCell>かな</TableCell>
                    {/*<TableCell>誕生日</TableCell>*/}
                    {/*<TableCell>認定区分</TableCell>*/}
                    <TableCell>編集</TableCell>
                    {/*<TableCell>園児コード</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(students) ? students.map(row => (
                    <TableRow key={row.id}>
                        <TableCell>{row.gakkyuNameKana}</TableCell>
                        <TableCell>{row.no}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.nameKana}</TableCell>
                        {/*<TableCell>{row.birthDay}</TableCell>*/}
                        {/*<TableCell>{row.paymentCertificationCategory}</TableCell>*/}
                        <TableCell>
                            <Button
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                color="default"
                                onClick={e => props.handleClickEditStudent(e, row.id)}>
                                <Icon color={'action'} iconkey={'edit'}/>
                            </Button>
                        </TableCell>
                        {/*<TableCell>{row.studentCode ? row.studentCode : getCodeButton(row.id)}</TableCell>*/}
                    </TableRow>
                )) : ''}
              </TableBody>
            </Table>
            </Paper>
          </Grid>
          
        </Grid>
      </Container>
    </main>
  );
}