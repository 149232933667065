import React from 'react';
import { makeStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmDialog from "../Common/ConfirmDialog";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function EditStudentFormDialog(props) {
    const classes = useStyles();
    const [isNotEditMode, setIsNotEditMode] = React.useState(true);
    const [confirmIsOpen, setConfirmIsOpen] = React.useState(false);
    const student = props.selectedStudent;

    const handleClose = () => {
        if (!isNotEditMode) {
            setConfirmIsOpen(true);
        } else {
            props.handleClose();
        }
    };

    const handleSave = () => {
        console.log('save student');
        setIsNotEditMode(true);
    };

    const handleAgreeAction = () => {
        setIsNotEditMode(true);
        props.handleClose();
    };

    const handlePublishStudentCode = () => {
        console.log('publish student code');
    };

    const editButton = () => {
        if (isNotEditMode) {
            return (
                <React.Fragment>
                    <Button
                        onClick={e => setIsNotEditMode(!isNotEditMode)} color={'primary'}>
                        編集する
                    </Button>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Button onClick={handleSave} color="primary">
                        保存する
                    </Button>
                </React.Fragment>
            );
        }
    };

    const studentCode = () => {
        if (true) {
            return (
                <React.Fragment>
                    <Button

                        onClick={handlePublishStudentCode} color="primary">
                        園児コードを取得
                    </Button>
                </React.Fragment>
            );
        }
    };

    if (student) {
        return (
            <React.Fragment>
                <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        園児情報を編集
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            園児情報を編集します。<br/>
                            学級を変更する場合は学級編成画面にて行ってください。
                        </DialogContentText>
                        <TextField
                            disabled={isNotEditMode}
                            margin="dense"
                            id="name"
                            name="name"
                            label="園児氏名"
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            className={classes.textField}
                            defaultValue={student.name}
                        />
                        <TextField
                            disabled={isNotEditMode}
                            margin="dense"
                            id="nameKana"
                            name='nameKana'
                            label="園児氏名（ふりがな）"
                            type="text"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            className={classes.textField}
                            defaultValue={student.nameKana}
                        />
                        <TextField
                            disabled={isNotEditMode}
                            margin="dense"
                            id="birthday"
                            name='birthday'
                            label="誕生日"
                            type="date"
                            width={'50%'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            className={classes.textField}
                            defaultValue={student.birthday}
                        />
                        <TextField
                            disabled={isNotEditMode}
                            margin="dense"
                            id="admissionDay"
                            name='admissionDay'
                            label="入園日"
                            type="date"
                            width={'50%'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            className={classes.textField}
                            defaultValue={student.admissionDay}
                        />
                        <TextField
                            disabled={isNotEditMode}
                            margin="dense"
                            id="commentByTeacher"
                            name="commentByTeacher"
                            label='備考欄（園側メモ/保護者側からは見えません）'
                            type="text"

                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            className={classes.textField}
                            multiline
                            rows="4"
                            rowsMax='10'
                            defaultValue={student.commentByTeacher}
                        />
                        <TextField
                            disabled
                            margin="dense"
                            id="commentByTeacher"
                            name="commentByTeacher"
                            label='備考欄（保護者申告欄/園側からは編集できません）'
                            type="text"

                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            className={classes.textField}
                            multiline
                            rows="4"
                            rowsMax='10'
                            defaultValue={student.commentByParent}
                        />
                    </DialogContent>
                    <DialogActions>
                        {editButton()}
                        <Button onClick={handleClose} color="primary">
                            閉じる
                        </Button>
                    </DialogActions>
                    <DialogActions>
                        {studentCode()}
                    </DialogActions>
                    <ConfirmDialog
                        title={''}
                        text={'編集を保存せずに閉じますか？'}
                        isOpen={confirmIsOpen}
                        setIsOpen={setConfirmIsOpen}
                        agreeAction={handleAgreeAction}
                    />
                </Dialog>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}