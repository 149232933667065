import React from 'react';
import moment from 'moment';
import { dateFormat } from "../../../_helpers/misc";
import {getGakkyuByGakurei, getStudentsByGakkyuId, gakkyuById} from '../../../_helpers';
import IndividualMonthlyUsage from '../../../components/ExtendedDaycare/BillingInfo/IndividualMonthlyUsage';
import {API_PATH} from "../../../constants";

export default class ForPersonalBilling extends React.Component {
    constructor(props) {
        super(props);

        const gakkyus = props.appState.gakkyu;
        const students = props.students.students;

        this.state = {
            date: dateFormat(new Date()),
            gakurei: gakkyus[0].gakurei,
            gakkyuId: gakkyus[0].id,
            studentId: students.length > 0 ? getStudentsByGakkyuId(gakkyus[0].id)[0].id : null,
            yearMonth: moment().format('YYYY-MM')
        }
    }

    componentDidMount() {
    }

    personalBillingCalc() {
        this.props.loadBillingInfoRecordsForPersonal(this.state.studentId, this.state.yearMonth);
    };

    download(path, fileName, data) {
        const token = localStorage.getItem('jwt');

        let url_string = API_PATH.baseURL + path;
        let url_obj = new URL(url_string);
        let params = new URLSearchParams(); // もしくは url_obj.searchParams
        data.map(d => params.append(d.key, d.value));
        url_obj.search = params.toString();

        fetch(url_obj.toString(),
            { method: 'GET', headers: new Headers([["authorization", token]])})
            .then(response => response.blob()).then(blob => {
            let anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            anchor.download = fileName;
            anchor.click();
        })
    }
    //　領収書を発行
    downloadVoucher = (e) => {
        let data = [{key:'yearMonth', value:this.state.yearMonth},{key:'studentId', value:this.state.studentId}];
        this.download(API_PATH.downloadVoucher, '【' + this.state.yearMonth + this.state.studentId + '】領収書.xlsx', data)
    };
    //　提供証明書を発行
    downloadProvisionCertificate = (e) => {
        let data = [{key:'yearMonth', value:this.state.yearMonth},{key:'studentId', value:this.state.studentId}];
        this.download(API_PATH.downloadProvisionCertificate, '【' + this.state.yearMonth + this.state.studentId + '】提供証明書.xlsx', data)
    };
    //　領収書兼提供証明書を発行
    downLoadVoucherAndProvisionCertificate = (e) => {
        let data = [{key:'yearMonth', value:this.state.yearMonth},{key:'studentId', value:this.state.studentId}];
        this.download(API_PATH.downLoadVoucherAndProvisionCertificate, '【' + this.state.yearMonth + this.state.studentId + '】領収書兼提供証明書.xlsx', data)
    };
    //　領収書兼提供証明書を発行
    downLoadVoucherAndProvisionCertificateForGakkyu = (e) => {
        let data = [{key:'yearMonth', value:this.state.yearMonth},{key:'gakkyuId', value:this.state.gakkyuId}];
        this.download(API_PATH.downLoadVoucherProvisionCertificateForGakkyu,
            '【' + this.state.yearMonth + gakkyuById(this.state.gakkyuId).nameKana + '】提供証明書兼領収書.xlsx', data)
    };

    handleChange(event, name) {
        if (name === 'gakurei') {
            const gid = getGakkyuByGakurei(event.target.value)[0].id;
            const stId = getStudentsByGakkyuId(gid)[0].id;
            this.props.loadBillingInfoRecordsForPersonal(stId, this.state.yearMonth);
            this.setState({
                ...this.state,
                [name]: event.target.value,
                gakkyuId: gid,
                studentId: stId
            })
        } else if (name === 'gakkyuId') {
            const stId = getStudentsByGakkyuId(event.target.value)[0].id;
            this.props.loadBillingInfoRecordsForPersonal(stId, this.state.yearMonth);
            this.setState({
                ...this.state,
                [name]: event.target.value,
                studentId: stId,
            })
        } else {
            if (name === 'studentId') {
                this.props.loadBillingInfoRecordsForPersonal(event.target.value, this.state.yearMonth);
            } else if (name === 'yearMonth') {
                this.props.loadBillingInfoRecordsForPersonal(this.state.studentId, event.target.value);
            }

            this.setState({
                ...this.state,
                [name]: event.target.value,
            });
        }
    };

    render() {

        return (
            <React.Fragment>
                <IndividualMonthlyUsage
                    {...this.props}
                    state={this.state}
                    handleChange={(e,name)=>this.handleChange(e,name)}
                    personalBillingCalc={(e)=>this.personalBillingCalc(e)}
                    downloadVoucher={(e)=>this.downloadVoucher(e)}
                    downloadProvisionCertificate={e=>this.downloadProvisionCertificate(e)}
                    downLoadVoucherAndProvisionCertificate={e=>this.downLoadVoucherAndProvisionCertificate(e)}
                    downLoadVoucherAndProvisionCertificateForGakkyu={e=>this.downLoadVoucherAndProvisionCertificateForGakkyu(e)}
                />
            </React.Fragment>
        );
    }
}