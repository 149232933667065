import React from 'react';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TabPanel from '../../../components/Common/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ShowGakkyuInformation from './ShowGakkyuInformation';
import {store} from "../../../_helpers";
import {loadAttendanceRecordsByGakkyuId, loadExtendedDaycareRecords} from "../../../actions";
import moment from "moment";

class Top extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tabId: 0,
            stAll: props.students.students,
        }
    }

    componentDidMount() {
        store.dispatch(loadExtendedDaycareRecords(null,  moment().format('YYYY-MM-DD')));
        store.dispatch(loadAttendanceRecordsByGakkyuId(null, moment().format('YYYY-MM-DD')));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


        handleChange(event, newValue) {
      this.setState({
          tabId: newValue,
      });
    }

    a11yProps(index) {
      return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
      };
    }


    render() {

        const classes = theme => ({

            title: {
                flexGrow: 1,
            },
            content: {
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
            },
            container: {
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
            },
            paper: {
                padding: theme.spacing(2),
                display: 'flex',
                overflow: 'auto',
                flexDirection: 'column',
            },
        });

        const title1 = '学級トップ';
        // const title2 = '保育日誌';
        // const title3 = '出欠集計';

        const selectedDate = moment().format('YYYY-MM-DD');
        const daycareRecords = this.props.extendedDaycare.extendedDaycareRecords;
        const attendanceRecords = this.props.attendance.attendanceRecords;
        const currentExtendedDaycareRecords = daycareRecords[selectedDate]
            ? daycareRecords[selectedDate].filter(r => (
                r.status === 'reservation'
                && r.gakkyuId === this.props.match.params.gakkyuId))
            :[];
        const currentAttendanceRecords = attendanceRecords[selectedDate]
            ? attendanceRecords[selectedDate].filter(r => (
                r.record_status === 'application'
                && r.gakkyuId === this.props.match.params.gakkyuId))
            :[];

        return (
            <React.Fragment>
                <AppBar position="static" color={'default'}>
                    <Tabs value={this.state.tabId} onChange={this.handleChange} aria-label="simple tabs">
                        <Tab label={title1} {...this.a11yProps(0)} />
                        {/*<Tab label={title2} {...a11yProps(1)} />*/}
                        {/*<Tab label={title3} {...a11yProps(2)} />*/}
                    </Tabs>
                </AppBar>
                <div className={classes.content}>
                    <Container maxWidth="lg" className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} className={classes.left}>

                                <TabPanel value={this.state.tabId} index={0}>
                                    <ShowGakkyuInformation
                                        {...this.props}
                                        label={title1}
                                        gakkyuId={this.props.match.params.gakkyuId}
                                        gakkyuStudents={this.state.students}
                                        // currentExtendedDaycareRecords={currentExtendedDaycareRecords}
                                        // currentAttendanceRecords={currentAttendanceRecords}
                                    />
                                </TabPanel>
                                {/*<TabPanel value={this.state.tabId} index={1}>*/}
                                {/*  <ListGakkyuDiary {...props} label={title2} gakkyuId={gakkyuId} gakkyuStudents={students}/>*/}
                                {/*</TabPanel>*/}

                                {/*<TabPanel value={this.state.tabId} index={2}>*/}
                                {/*  <ManageGakkyuAbsence {...props} label={title3} gakkyuId={gakkyuId} gakkyuStudents={students}/>*/}
                                {/*</TabPanel>*/}
                            </Grid>
                        </Grid>


                    </Container>

                </div>
            </React.Fragment>
        );
    }
};

export default withRouter(Top);