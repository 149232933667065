import React from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import ExtendedDaycareTop from '../../../components/ExtendedDaycare/Top';
import {store, debug} from "../../../_helpers";
import {loadExtendedDaycareRecords, loadScheduleExtendedDaycare, loadScheduleForMonth} from "../../../actions";
import {EXDAYCARE_PATH} from "../../../constants";

class Top extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentSelectedDate: this.getSelectedDateFromProps(),
        };
    }

    componentDidMount() {
        debug('container extended daycare top did mount!');
        this.loadSchedule(this.dateToMonth(this.state.currentSelectedDate));
        this.loadExtendedDaycareRecord(this.state.currentSelectedDate);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        debug('container extended daycare top did update!' + prevState.currentSelectedDate + ':' + this.state.currentSelectedDate);
        if (prevState.currentSelectedDate !== this.state.currentSelectedDate) {
            this.loadExtendedDaycareRecord(this.state.currentSelectedDate);
        }
        if (this.dateToMonth(prevState.currentSelectedDate)
            !== this.dateToMonth(this.state.currentSelectedDate)) {
            this.loadSchedule(this.dateToMonth(this.state.currentSelectedDate));
        }
    }


    /**
     * 仕様注：tabKey=for-dayのときはtabParamから日付を取得
     * tabParamがないか、tabKeyがfor-day以外ならデフォルトは現在時刻に基づく
     */
    getSelectedDateFromProps() {
        const tabKey = this.props.match.params.tabKey;
        const tabParam = this.props.match.params.tabParam;
        let selectedDate = tabParam;
        if ((tabKey === EXDAYCARE_PATH[0].key && !tabParam)
            || tabKey !== EXDAYCARE_PATH[0].key) {
            selectedDate = moment().format('YYYY-MM-DD');
            // //TODO:
            // selectedDate = '2019-11-27';
        }
        return selectedDate;
    }

    /**
     * misc
     */
    tabKeyToIndex(param) {
        const path = EXDAYCARE_PATH.find(path => path.key === param);
        return path ? path.index : 0;
    }
    indexToTabKey(index) {
        const path = EXDAYCARE_PATH.find(path => path.index === parseInt(index));
        return path ? path.key : EXDAYCARE_PATH[0].key;
    }
    dateToMonth(date) {
        return moment(date, 'YYYY-MM-DD').format('YYYY-MM');
    }

    pushHistoryForExtendedDaycare(index, date=null) {
        let path = '/manage/extend-daycare/' + this.indexToTabKey(index);
        if (date) {
            path += '/' + date;
        }
        this.props.history.push(path);
    }

    tabChange(e, newValue) {
        if (this.indexToTabKey(newValue) === 'for-day') {
            this.pushHistoryForExtendedDaycare(newValue, this.state.currentSelectedDate);
        } else {
            this.pushHistoryForExtendedDaycare(newValue);
        }
    }

    /**
     * params setter
     */
    setCurrentSelectedDate(date) {
        if (this.props.match.params.tabKey === 'for-day') {
            this.pushHistoryForExtendedDaycare(0, date);
        }
        this.setState({
            currentSelectedDate:date,
        });
    }

    /**
     * loader
     */
    loadSchedule(yearMonth) {
        debug('load extended daycare schedule:' + yearMonth);
        store.dispatch(loadScheduleExtendedDaycare(yearMonth));
        store.dispatch(loadScheduleForMonth(yearMonth));
    }

    loadExtendedDaycareRecord(date) {
        store.dispatch(loadExtendedDaycareRecords(null, date));
    }

    render() {
        const tabKey = this.props.match.params.tabKey;
        const tabParam = this.props.match.params.tabParam;
        const tabSubParam1 = this.props.match.params.tabSubParam1;
        const tabSubParam2 = this.props.match.params.tabSubParam2;

        return (
            <React.Fragment>
                <ExtendedDaycareTop
                    {...this.props}
                    tabIndex={this.tabKeyToIndex(tabKey)}
                    tabKey={tabKey}
                    tabParam={tabParam}
                    tabSubParam1={tabSubParam1}
                    tabSubParam2={tabSubParam2}
                    currentSelectedDate={this.state.currentSelectedDate}
                    setCurrentSelectedDate={e => this.setCurrentSelectedDate(e)}
                    handleTabChange={(e,newValue) => this.tabChange(e, newValue)}
                />
            </React.Fragment>
        );
    }
};

export default withRouter(Top);