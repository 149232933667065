import React, {Component} from 'react';

class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    async componentDidMount() {
        
    }

    render() {
    return (
      <div className="container">
        <div className="row">
          
        </div>
      </div>
    )
  }
}

export default Signup;