import {ACTION_TYPE, API_PATH} from "../constants";
import {axiosPost} from "../_helpers";
import moment from 'moment';

export function loadYearMonthsByCategory(category) {
    return dispatch => {
        return axiosPost(API_PATH.loadScheduleYearMonthsByCategory, {category})
            .then(res => dispatch(loadSuccessYearMonths(res)))
            .catch(err => dispatch(loadErrorSchedule(err)));
    }
}

export function loadScheduleExtendedDaycare(currentYearMonth=moment().format('YYYY-MM')) {
    return dispatch => {
        return axiosPost(API_PATH.loadExtendedDaycareSchedule, {yearMonth: currentYearMonth})
            .then(res => dispatch(loadSuccessExtendedDaycareSchedule(res)))
            .catch(err => dispatch(loadErrorSchedule(err)));
    }
}

export function loadScheduleForMonth(currentYearMonth=moment().format('YYYY-MM')) {
    return dispatch => {
        return axiosPost(API_PATH.loadScheduleForMonth, {yearMonth: currentYearMonth})
            .then(res => dispatch(loadSuccessSchedule(res)))
            .catch(err => dispatch(loadErrorSchedule(err)));
    }
}


const loadSuccessYearMonths = res => {return {
    type: ACTION_TYPE.LOAD_SCHEDULE_YEAR_MONTHS_BY_CATEGORY,
    category: res.data.category,
    yearMonths: res.data.yearMonths,
}};
const loadSuccessSchedule = res => {return {
    type: ACTION_TYPE.LOAD_SCHEDULE_FOR_MONTH,
    calendar: res.data.calendar,
}};

const loadSuccessExtendedDaycareSchedule = res => {return {
    type: ACTION_TYPE.LOAD_SCHEDULE_EXTEND_DAYCARE_FINISH,
    extendedDaycareSchedule: res.data.extendedDaycareSchedule,
}};
const loadErrorSchedule = error => {return {
    type: ACTION_TYPE.ERROR_SCHEDULE_REQUEST,
    error: error
}};
