import React from 'react';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import { getGakkyuByGakurei, getStudentsByGakkyuId } from '../../../_helpers';
import moment from 'moment';
import clsx from "clsx";


const useStyles = makeStyles(theme => ({
    seeMore: {
        marginTop: theme.spacing(3),
        textAlign: 'right',
    },
    rowHeader: {
        // backgroundColor: green[300],
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3),
        float: 'right',
    },
    textField: {
    },
    commentArea: {
        minWidth: '90%',
        minHeight: '150px',
    },
    zeroMarginY: {
        marginTop: 0,
        marginBottom: 0,
    },
}));


function IndividualMonthlyUsage(props) {
    const classes = useStyles();

    //TODO: 暫定的に選択月を算出
    const fromMonth = moment('2019-07', 'YYYY-MM');
    const currentMonth = moment();
    let monthSelection = [];
    let t = moment(fromMonth, 'YYYY-MM');
    while(t.isSameOrBefore(currentMonth)) {
        monthSelection.push(t.clone());
        t.add(1, 'M');
    }

    function showListRecords() {
        const records = props.extendedDaycare.billingInfoRecordsForPersonal[props.state.yearMonth+'--'+props.state.studentId];
        if (records != null) {
            const billingRecords = records.billingRecords;
            const monthEndBilling = records.monthEndBilling;
            return (<Table size="large">
                <TableBody>
                    <TableRow>
                        <TableCell>年月日</TableCell>
                        <TableCell>開始時間-終了時間</TableCell>
                        <TableCell>利用時間(分)</TableCell>
                        <TableCell>預かり保育料</TableCell>
                        <TableCell>減免額</TableCell>
                        <TableCell>預かり保育料請求額</TableCell>
                        <TableCell>おやつ代</TableCell>
                        <TableCell>請求額</TableCell>
                    </TableRow>
                    {billingRecords.map(r => {
                       return(
                           <TableRow>
                               <TableCell>{r.extendsDate}</TableCell>
                               <TableCell>{moment(r.fromTime,'HH:mm:ss').format('HH:mm') + '-' + moment(r.toTime,'HH:mm:ss').format('HH:mm')}</TableCell>
                               <TableCell>{r.useTime}</TableCell>
                               <TableCell>{r.usageCharge}</TableCell>
                               <TableCell>{r.exemption}</TableCell>
                               <TableCell>{r.usageAmount}</TableCell>
                               <TableCell>{r.snackAmount}</TableCell>
                               <TableCell>{r.amount}</TableCell>
                           </TableRow>
                       );
                    })}
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{'利用時間計'}</TableCell>
                        <TableCell>{'保育料計'}</TableCell>
                        <TableCell>{'減免額計'}</TableCell>
                        <TableCell>{'保育料請求額計'}</TableCell>
                        <TableCell>{'おやつ代計'}</TableCell>
                        <TableCell>{'総請求額'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>{monthEndBilling.totalUseTime}</TableCell>
                        <TableCell>{monthEndBilling.totalUsageCharge}</TableCell>
                        <TableCell>{monthEndBilling.totalExemption}</TableCell>
                        <TableCell>{monthEndBilling.totalUsageAmount}</TableCell>
                        <TableCell>{monthEndBilling.totalSnackAmount}</TableCell>
                        <TableCell>{monthEndBilling.totalAmount}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>);
        }
    }

    if (!props.appState.gakkyu[0]) {
        return (
            <React.Fragment>
                loading
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>

                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>精算する月</TableCell>
                            <TableCell>
                                <Select
                                    native
                                    value={props.state.month}
                                    onChange={e=>props.handleChange(e,'yearMonth')}
                                    input={
                                        <OutlinedInput name="month"  id="outlined-month-native-simple" />
                                    }
                                >
                                    {monthSelection.map(t => {
                                        return (<option key={'from-'+ t.format('YYYYMM') +'-month'}
                                                        value={t.format('YYYY-MM')}
                                                        selected={t.format('YYYY-MM')}
                                        >
                                            {t.format('YYYY年MM月')}
                                        </option>)
                                    })}
                                </Select>
                            </TableCell>
                            <TableCell colSpan={3}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        native
                                        value={props.state.gakurei}
                                        onChange={e=>props.handleChange(e,'gakurei')}
                                        input={
                                            <OutlinedInput name="gakurei"  id="outlined-gakurei-native-simple" />
                                        }
                                    >
                                        {props.appState.gakunen.map(g => {
                                            return (<option key={g.id+'-gakurei'} value={g.gakurei}>{g.gakunenName}</option>)
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        native
                                        value={props.state.gakkyuId}
                                        onChange={e=>props.handleChange(e,'gakkyuId')}
                                        input={
                                            <OutlinedInput name="gakkyu"  id="outlined-gakkyu-native-simple" />
                                        }
                                    >
                                        {getGakkyuByGakurei(props.state.gakurei).map(g => {
                                            return (<option key={g.id+'-gakkyuId'} value={g.id}>{g.name}</option>)
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <Select
                                        native
                                        value={props.state.studentId}
                                        onChange={e=>props.handleChange(e,'studentId')}
                                        input={
                                            <OutlinedInput name="studentId"  id="outlined-studentId-native-simple" />
                                        }
                                    >
                                        {getStudentsByGakkyuId(props.state.gakkyuId).map(st => {
                                            return (<option key={st.id+'-studentId'} value={st.id}>{st.nameKana}</option>)
                                        })}
                                    </Select>
                                </FormControl>

                            </TableCell>
                            {/*<TableCell>*/}
                            {/*    <Button variant="contained" color="primary" className={classes.button} onClick={e=>props.personalBillingCalc(e)}>*/}
                            {/*        計算*/}
                            {/*    </Button>*/}
                            {/*</TableCell>*/}
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={6}>
                                {/*<Button variant="contained" color="primary" className={clsx(classes.button, classes.zeroMarginY)} onClick={e=>props.downLoadVoucherAndProvisionCertificate(e)}>*/}
                                {/*    領収書兼提供証明書を出力*/}
                                {/*</Button>*/}

                                <Button variant="contained" color="primary" className={clsx(classes.button, classes.zeroMarginY)} onClick={e=>props.downLoadVoucherAndProvisionCertificateForGakkyu(e)}>
                                    選択された学級の領収書兼提供証明書を出力
                                </Button>

                                {/*<Button variant="contained" color="primary" className={clsx(classes.button, classes.zeroMarginY)} onClick={e=>props.downloadProvisionCertificate(e)}>*/}
                                {/*    提供証明書を出力*/}
                                {/*</Button>*/}

                                {/*<Button variant="contained" color="primary" className={clsx(classes.button, classes.zeroMarginY)} onClick={e=>props.downloadVoucher(e)}>*/}
                                {/*    領収書を出力*/}
                                {/*</Button>*/}
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
                {showListRecords()}
            </React.Fragment>
        );
    }
}

export default IndividualMonthlyUsage;