export const ENV_PARAM = {
    develop: 'develop',
    staging: 'staging',
    production: 'production',
};

// export const ENVIRONMENT = ENV_PARAM.develop;
// export const ENVIRONMENT = ENV_PARAM.staging;
export const ENVIRONMENT = ENV_PARAM.develop;

export const API_BASEPATH = () => {
    switch (ENVIRONMENT) {
        case 'develop':
            return 'https://dev.api.otama.rabbit-knot.co.jp/';
        case 'staging':
            return 'https://staging.api.otamajakushi.jp/';
        case 'production':
            return 'https://api.otamajakushi.jp/';
        default:
            return '';
    }
};

export const API_PATH = {
    baseURL: API_BASEPATH(),

    //AUTH
    authentication: 'auth/login',
    refreshToken: 'auth/refresh',
    loadAuthUser: 'auth/load-auth-user',

    //DOWNLOAD FILES
    downloadStudentCodeList: 'students/download/student-code-list',
    loadBillingInfoRecordsForPersonal: 'extended-daycare/billing-info-for-personal',
    downloadVoucher: 'extended-daycare/billing/voucher',
    downloadProvisionCertificate: 'extended-daycare/billing/provision-certificate',
    downLoadVoucherAndProvisionCertificate: 'extended-daycare/billing/voucher-provision-certificate',
    downLoadVoucherProvisionCertificateForGakkyu: 'extended-daycare/billing/gakkyu-voucher-provision-certificate',
    downLoadAggregateForWeekday: 'extended-daycare/aggregate/download-for-weekday',

    //DAY SCHEDULE
    loadScheduleForMonth: 'day-schedule/for-month',//params: 'yearMonth
    loadScheduleYearMonthsByCategory: 'day-schedule/year-months',//params: 'category'

    //EXTENDED DAYCARE
    loadExtendedDaycareRecords: 'extended-daycare/records',
    loadExtendedDaycareSchedule: 'extended-daycare/schedule',
    registerExtendedDaycareRecords: 'extended-daycare/register',
    changeStatusExtendedDaycareRecords: 'extended-daycare/change-status',
    changeOptionsExtendedDaycareRecords: 'extended-daycare/change-options',
    leavingExtendedDaycare: 'extended-daycare/leaving',
    deleteExtendedDaycareRecords: 'extended-daycare/delete',

    //ATTENDANCE
    loadAttendanceRecordsByGakkyuId: 'attendance/records/gakkyu',
    registerAttendanceRecords: '',
    changeStatusAttendanceRecords: '',
    deleteAttendanceRecords: '',


    //STUDENTS
    loadStudents: 'students',
    loadGakkyus: 'gakkyus',
    registerStudent:'students/register',
    updateStudent:'students/update',
    getStudent:'students/student',
    publishStudentCode:'students/publishCode',

};