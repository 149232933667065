import React from 'react';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '../../Accessory/Icon';

const useStyles = makeStyles(theme => ({
    listNested: {
      paddingLeft: theme.spacing(4),
      backgroundColor: theme.palette.primary.main,
    },
    listMoreNested: {
        paddingLeft: theme.spacing(6),
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      },
  }));


function InnerListItem(props) {

    const classes = useStyles();
    const [state, setState] = React.useState({
        isOpen: false,
        item:props.item,
      });

    const handleClick = () => {
        setState({...state, isOpen: !state.isOpen});
    }

    if (state.item.children.length > 0) {
        return (
            <div>
            <ListItem button className={classes.listNested} onClick={handleClick}>
                <ListItemIcon>
                {state.isOpen ? <Icon iconkey='expandMore' /> : <Icon iconkey='chevronRight' />}
                </ListItemIcon>
                <ListItemText primary={state.item.title} />
                </ListItem>
                <Collapse in={state.isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                
                {state.item.children.map((_i) => {
                    return (
                        <ListItem button key={_i.key} className={classes.listMoreNested} onClick={e => props.history.push(_i.link)}>
                            <ListItemIcon><Icon iconkey={_i.iconKey}/></ListItemIcon>
                            <ListItemText primary={_i.title} />
                        </ListItem>
                    );})}
                </List>
            </Collapse>
            </div>);
    } else {
        return (
            <ListItem button key={state.item.key} className={classes.listMoreNested} onClick={e => props.history.push(state.item.link)} >
            <ListItemIcon><Icon iconkey={state.item.iconKey}/></ListItemIcon>
                <ListItemText primary={state.item.title} />
            </ListItem>);
    }
}
export default InnerListItem;