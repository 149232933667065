import { history, axiosPost } from '../_helpers';
import {API_PATH} from "../constants";


export const LOGIN_FINISH = 'LOGIN_FINISH';
const loginSuccess = res => {return {
    type: LOGIN_FINISH,
    token: res.data.token,}};
const loginError = err => {return {type: LOGIN_FINISH, error: err}};
export const loginAuth = (email, password) => {
    return dispatch => {
        return axiosPost(API_PATH.authentication, {email: email, password: password})
            .then(res => {
                    localStorage.setItem('jwt', res.data.token);
                    dispatch(loginSuccess(res));
                    history.push('/');
                }
            ).catch(err => dispatch(loginError(err)))
    }
};

export const LOAD_AUTH_USER = 'LOAD_AUTH_USER';
const loadAuthUserSuccess = res => {return {
    type: LOAD_AUTH_USER,
    user: res.data.user,
    token: res.data.token,}};
const loadAuthUserError = err => {return {type: LOAD_AUTH_USER, error: err}};
export const loadAuthUser = (token) => {
    return dispatch => {
        return axiosPost(API_PATH.loadAuthUser, {})
            .then(res => {
                    dispatch(loadAuthUserSuccess(res));
                }
            ).catch(err => {
                dispatch(loadAuthUserError(err));
                dispatch(logoutAuth());
            })
    }
}

export const LOGOUT_AUTH = 'LOGOUT_AUTH';
export const logoutAuth = () => {
    return {
        type: LOGOUT_AUTH,
    }
};