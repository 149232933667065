import React from 'react';
import {API_PATH} from "../../../../constants";
import StudentList from '../../../../components/ManagePages/StudentList';
import EditStudentFormDialog from "../../../../components/ManagePages/EditStudentFormDialog";
import {bindActionCreators} from "redux";
import * as Actions from "../../../../actions";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";



export default class StudentListContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            gakkyus: props.appState.gakkyu,
            currentGakkyuId: props.appState.gakkyu[0].id,
            editDialogIsOpen: false,
            selectedStudent: null,
        }
    }

    downloadStudentCodes(e) {
        // alert('園児コードリストを取得')
        const token = localStorage.getItem('jwt');

        let data = [{key:'gakkyuId', value:this.state.currentGakkyuId}];
        let url_string = API_PATH.baseURL + API_PATH.downloadStudentCodeList;
        let url_obj = new URL(url_string);
        let params = new URLSearchParams(); // もしくは url_obj.searchParams
        data.map(d => params.append(d.key, d.value));
        url_obj.search = params.toString();

        fetch(url_obj.toString(),
            { method: 'GET', headers: new Headers([["authorization", token]])})
            .then(response => response.blob()).then(blob => {
            let anchor = document.createElement("a");
            anchor.href = window.URL.createObjectURL(blob);
            anchor.download = '園児コードリスト--' + this.state.gakkyus.find(g => g.id === this.state.currentGakkyuId).nameKana + '.xlsx';
            anchor.click();
        })
    }

    handleChangeGakkyu(e) {
        this.setState({
            currentGakkyuId: e.target.value,
        });
    }

    handleClickEditStudent(e, id) {
        const student = this.props.students.students.find(s => s.id === id);
        this.setState({
            editDialogIsOpen: true,
            selectedStudent: student,
        });
    }

    handleCloseEditStudentDialog() {
        this.setState({
            editDialogIsOpen: false,
        });
    }


    render() {
        return (
            <React.Fragment>
                <StudentList
                    {...this.props}
                    gakkyus={this.state.gakkyus}
                    currentGakkyuId={this.state.currentGakkyuId}
                    downloadStudentCodes={e=> this.downloadStudentCodes(e)}
                    handleChangeGakkyu={e => this.handleChangeGakkyu(e)}
                    handleClickEditStudent={(e, id) => this.handleClickEditStudent(e, id)}
                    handleClickAddStudent={e => this.handleClickAddStudent(e)}
                />
                <EditStudentFormDialog
                    {...this.props}
                    open={this.state.editDialogIsOpen}
                    handleClose={e => this.handleCloseEditStudentDialog(e)}
                    selectedStudent={this.state.selectedStudent}
                />
            </React.Fragment>
        );
    }
}