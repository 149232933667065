import {ACTION_TYPE} from "../constants";
import jwtDecode from 'jwt-decode';
import moment from 'moment';

const jwt = localStorage.getItem('jwt');
const isAuthenticated = () => {
    if (jwt) {
        const jwtData = jwtDecode(jwt);
        const now = moment();
        const expire = moment(jwtData.expire,'X');
        return expire.isAfter(now);
    }
    return false;
};
const initialState = {
    isLoggedIn: isAuthenticated(),
    token: jwt ? jwt : null,
    permissions: [],
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.LOGIN_FINISH:
            if (action.token) {
                localStorage.setItem('jwt', action.token);
                return Object.assign({}, state, {
                    isLoggedIn: true,
                    token: action.token,
                });
            } else {
                return Object.assign({}, state, {
                    isLoggedIn: false,
                    error: action.err
                });
            }
        case ACTION_TYPE.LOAD_AUTH_USER:
            if (action.user) {
                return Object.assign({}, state, {
                    isLoggedIn: true,
                    authUser: action.user,
                    token: action.token,
                });
            } else {
                return Object.assign({}, state, {
                    isLoggedIn: false,
                    error: action.err
                });
            }
        case ACTION_TYPE.LOGOUT_AUTH:
            localStorage.clear();
            return {};
        default:
            return state;
    }
}