import React from 'react';
import clsx from 'clsx';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import Title from '../../components/Text/Title';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "../../components/Accessory/Icon";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
    textAlign: 'right',
  },
  rowHeader: {
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(3),
    float: 'right',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  listCell: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  gakkyuNameCell: {
    width: '70px',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  attendanceCell: {
    width: '100px',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  nameCell: {
    width: '160px',
    fontSize: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  timeCell: {
    width: '110px',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  optionCell: {
    width: '80px',
    textAlign: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  commentArea: {
    maxWidth: '50%',
    minWidth: '150px'
  },
  iconCell: {
    textAlign: 'center',
    width: '100px',
  },
}));


export default function ListGakkyuExtendedDaycareRecordsForDay(props) {

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  // 本日の預かり保育
  const currentSelectedDate = moment().format('YYYY-MM-DD');
  const daycareRecords = props.extendedDaycare.extendedDaycareRecords;
  // const attendanceRecords = props.attendance.attendanceRecords;
  const currentExtendedDaycareRecords = daycareRecords[currentSelectedDate] ? daycareRecords[currentSelectedDate]:[];
  // const currentAttendanceRecords = attendanceRecords[currentSelectedDate] ? attendanceRecords[currentSelectedDate]:[];
  const recordDelete = (e, row) => {
    props.deleteExtendedDaycareRecords(row.id);
  };

  const handleChangeHasSnack = (e, row) => {
    props.changeOptionsExtendedDaycareRecords(row.id, 'hasAteSnack', !row.hasAteSnack);
  };
        
  return (
    <main className={props.contentClass}>
        <div className={props.toolbarClass}/>
        <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {/*<Grid item xs={12}>*/}
          {/*<Paper className={fixedHeightPaper}>*/}
          {/*  <Title>本日の欠席等連絡</Title>*/}
          {/*  <Title>{'欠席' + currentAttendanceRecords.filter(*/}
          {/*          row => row.gakkyuId === props.gakkyuId*/}
          {/*          && row.status !== 'attendance').length +' 名('*/}
          {/*          + '出席停止:' + currentAttendanceRecords.filter(*/}
          {/*              row => row.gakkyuId === props.gakkyuId*/}
          {/*              && row.status === 'attendance_stop').length + ' 名,'*/}
          {/*          + ' 忌引き:' + currentAttendanceRecords.filter(*/}
          {/*              row => row.gakkyuId === props.gakkyuId*/}
          {/*              && row.status === 'bereavement').length + ' 名)'}*/}
          {/*  </Title>*/}
          {/*  <Table size="small">*/}
          {/*    <TableHead>*/}
          {/*      <TableRow>*/}
          {/*        <TableCell className={classes.attendanceCell}>出欠</TableCell>*/}
          {/*        <TableCell className={classes.nameCell}>かな</TableCell>*/}
          {/*        <TableCell className={classes.listCell}>理由</TableCell>*/}
          {/*        <TableCell className={classes.commentArea}>連絡</TableCell>*/}
          {/*      </TableRow>*/}
          {/*    </TableHead>*/}
          {/*    <TableBody>*/}
          {/*      {currentAttendanceRecords.map(row => {*/}
          {/*        if (row.gakkyuId === props.gakkyuId && row.status !== 'attendance') {*/}
          {/*          let label = '欠席';*/}
          {/*          if (row.status === 'attendance_stop') {*/}
          {/*            label = '出席停止';*/}
          {/*          } else if (row.status === 'bereavement') {*/}
          {/*            label = '忌引き';*/}
          {/*          }*/}
          {/*          return (*/}
          {/*              <TableRow key={row.id + '-absents'}>*/}
          {/*                <TableCell className={classes.attendanceCell}>{label}</TableCell>*/}
          {/*                <TableCell className={classes.nameCell}>{row.studentNameKana}</TableCell>*/}
          {/*                <TableCell className={classes.listCell}>{row.reason}</TableCell>*/}
          {/*                <TableCell className={classes.commentArea}>{row.comment}</TableCell>*/}
          {/*              </TableRow>*/}
          {/*      )}})}*/}
          {/*    </TableBody>*/}
          {/*  </Table>*/}

          {/*  <Title>その他連絡</Title>*/}
          {/*  <Table size="small">*/}
          {/*    <TableHead>*/}
          {/*      <TableRow>*/}
          {/*        <TableCell className={classes.attendanceCell}>出欠</TableCell>*/}
          {/*        <TableCell className={classes.nameCell}>かな</TableCell>*/}
          {/*        <TableCell className={classes.timeCell}>遅刻</TableCell>*/}
          {/*        <TableCell className={classes.timeCell}>早退</TableCell>*/}
          {/*        <TableCell className={classes.commentArea}>連絡</TableCell>*/}
          {/*      </TableRow>*/}
          {/*    </TableHead>*/}
          {/*    <TableBody>*/}
          {/*      {currentAttendanceRecords.map(row => {*/}
          {/*        if (row.gakkyuId === props.gakkyuId && row.status === 'attendance') {*/}
          {/*          let label = '出席';*/}
          {/*          if (row.comeTime !== null && row.leaveTime !== null) {*/}
          {/*            label = '遅/早';*/}
          {/*          } else if (row.comeTime !== null && row.leaveTime === null) {*/}
          {/*            label = '遅刻';*/}
          {/*          } else if (row.comeTime === null && row.leaveTime !== null) {*/}
          {/*            label = '早退';*/}
          {/*          }*/}
          {/*          return (*/}
          {/*              <TableRow key={row.id + '-absents'}>*/}
          {/*                <TableCell className={classes.attendanceCell}>{label}</TableCell>*/}
          {/*                <TableCell className={classes.nameCell}>{row.studentNameKana}</TableCell>*/}
          {/*                <TableCell className={classes.timeCell}>{row.comeTime}</TableCell>*/}
          {/*                <TableCell className={classes.timeCell}>{row.leaveTime}</TableCell>*/}
          {/*                <TableCell className={classes.commentArea}>{row.comment}</TableCell>*/}
          {/*              </TableRow>*/}
          {/*          )}})}*/}
          {/*    </TableBody>*/}
          {/*  </Table>*/}
          {/*  </Paper>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <Paper className={fixedHeightPaper}>
              <Title>本日の預かり保育</Title>
              <Title>申請人数 {currentExtendedDaycareRecords.length} 名</Title>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.gakkyuNameCell}>くみ</TableCell>
                    <TableCell className={classes.nameCell}>なまえ</TableCell>
                    <TableCell className={classes.timeCell}>保育時間</TableCell>
                    <TableCell className={classes.optionCell}>おやつ</TableCell>
                    <TableCell className={classes.optionCell}>課外体育</TableCell>
                    <TableCell className={classes.commentArea}>備考</TableCell>
                    <TableCell className={classes.iconCell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentExtendedDaycareRecords.map(row => {
                    if (row.gakkyuId === props.gakkyuId) {
                      return (
                          <TableRow key={row.id + '-extends-res'} className={classes.studentRow}>
                            <TableCell className={classes.gakkyuNameCell}>{row.gakkyuNameKana}</TableCell>
                            <TableCell className={classes.nameCell}>{row.studentNameKana}</TableCell>
                            <TableCell className={classes.timeCell}>{moment(row.fromTime,'HH:mm:ss').format('HH:mm')}~{moment(row.toTime,'HH:mm:ss').format('HH:mm')}</TableCell>
                            <TableCell className={classes.optionCell}>
                              <Checkbox className={classes.inputCheckbox} checked={row.hasAteSnack ? row.hasAteSnack : false} onChange={e => handleChangeHasSnack(e, row)} value={'hasAteSnack'} />
                            </TableCell>
                            <TableCell className={classes.optionCell}>
                              {row.hasJoinGymnastics ? <Icon color={'secondary'} iconkey={'accessibilityNew'}/> : ''}
                            </TableCell>
                            <TableCell className={classes.commentArea}>
                              {row.comment}
                            </TableCell>
                            <TableCell className={classes.iconCell}>
                              <Button
                                  aria-controls="customized-menu"
                                  aria-haspopup="true"
                                  color="default"
                                  onClick={e => recordDelete(e, row)}>
                                <Icon color={'action'} iconkey={'delete'}/>
                              </Button>
                            </TableCell>
                          </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}